import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Navbar from "../../components/navbar/navbar"
import NavbarMobile from "../../components/navbar/navbarMobile"
import BannerWithForm from "../../components/openday/implantologia/bannerWithForm"
import Footer from "../../components/footer/footer"
import FooterMobile from "../../components/footer/footerMobile"
import BoxWithText from "../../components/openday/implantologia/boxWithText"
import ImageWithText from "../../components/openday/imageWithText"
import BoxWithTextMobile from "../../components/openday/implantologia/boxWithTextMobile"
import ImageWithTextMobile from "../../components/openday/imageWithTextMobile"
import BoxInfo from "../../components/openday/implantologia/boxInfo"
import FAQAccordion from "../../components/openday/implantologia/FAQAccordion"
import BoxSlides from "../../components/openday/implantologia/boxSlides"
import BannerWithTextAndFormMobile from "../../components/openday/implantologia/bannerWithFormMobile"
import BoxSlidesMobile from "../../components/openday/implantologia/boxSlidesMobile"
import BoxInfoMobile from "../../components/openday/implantologia/boxInfoMobile"
import FAQAccordionMobile from "../../components/openday/implantologia/FAQAccordionMobile"
import BoxOldPeople from "../../components/openday/implantologia/boxOldPeople"
import BoxOldPeopleMobile from "../../components/openday/implantologia/boxOldPeopleMobile"

const ImplantologiaOpenPage = ({ data }) => {
  return (
    <>
      <SEO titleTemplate="Open Day - Implantologia" title="Carreira Dental Clinic" />
      <Layout home mobile={useBreakpoint().mobile}>
        {useBreakpoint().mobile ? (
          <>
            <NavbarMobile />
            <BannerWithTextAndFormMobile openday="ortodontia" />
            <BoxOldPeopleMobile />
            <BoxSlidesMobile />
            <BoxWithTextMobile />
            <BoxInfoMobile />
            <FAQAccordionMobile />
            <FooterMobile data={data.globalJson.footer} />
          </>
        ) : (
          <>
            <Navbar />
            <BannerWithForm openday="ortodontia" />
            <BoxOldPeople />
            <BoxSlides />
            <BoxWithText />
            <BoxInfo />
            <FAQAccordion />
            <Footer data={data.globalJson.footer} footerTop />
          </>
        )}
      </Layout>
    </>
  )
}

export default ImplantologiaOpenPage

export const Json = graphql`
  query openimpla {
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
