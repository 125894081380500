import React from "react"
import styled from "styled-components"
import implant1 from "../../../images/openday/implantologia/implant-1.png"
import implant2 from "../../../images/openday/implantologia/implant-2.png"
import allOn4 from "../../../images/openday/implantologia/all-on-4.png"
import tablet from "../../../images/openday/implantologia/tablet-horizontal.png"
import videoImplant from "../../../videos/Dr.Carreira_HQ.mp4"
import font from "../../../fonts/fonts.module.scss"
import poster from "../../../images/openday/implantologia/poster.png"

const Container = styled.div`
  position: relative;
  background: #e6d1c0;

  .implant-img-first {
    grid-column: 7 / -1;
    width: 100%;
    border-radius: 5px;
  }
  .implant-img-second {
    grid-column: 1 / 6;
    width: 100%;
    border-radius: 5px;
  }

  .tablet {
    grid-column: 1 / 13;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15vh;
    padding-bottom: 50vh;

    .tablet-img {
      width: 100%;
    }
  }

  .video {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      width: 88vw;
      border-radius: 40px;
    }
  }

`

// Caixa do lado esquerdo com dimensões fixas
const LeftTextBox = styled.div`
  grid-column: 1 / 6;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: clamp(2.5rem, 0.4167rem + 2.7778vw, 3.75rem);
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    font-size: clamp(1.25rem, 0.2083rem + 1.3889vw, 1.875rem);
    margin-bottom: 90px;
  }

  li {
    font-size: clamp(1.25rem, 0.8333rem + 0.5556vw, 1.5rem);
    list-style-type: none;
    padding-bottom: 10px;
  }
`
const RightTextBox = styled.div`
  grid-column: 7 / -1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: clamp(2.5rem, 0.4167rem + 2.7778vw, 3.75rem);
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    font-size: clamp(1.25rem, 0.2083rem + 1.3889vw, 1.875rem);
    margin-bottom: 90px;
  }
`

const BoxWithText = () => {
  return (
    <Container>
      <div className="grid-container">
        <div className="tablet">
          <img src={tablet} className="tablet-img" />
          <div className="video">
            <video
              muted
              playsinline
              controls
              poster={poster}
              title="Open day - Implantologia Avançada - Dr. Adriano Carreira"
            >
              <source src={videoImplant} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="grid-container">
        <LeftTextBox>
          <h2 className={font.rB}>técnica all-on-4</h2>
          <p className={font.rR}>
            Uma técnica que lhe dará uma vida inteira de sorrisos
          </p>
          <ul className={font.rR}>
            <li>
              {" "}
              • Com apenas 4 implantes, recupera a<b> dentição completa;</b>
            </li>
            <li>
              {" "}
              • Num só dia, colocará uma <b>prótese fixa</b> confortável segura;
            </li>
            <li>
              {" "}
              • Em apenas 3 horas, <b>sorrirá com confiança;</b>
            </li>
            <li>
              • Após <b>3 a 5 dias</b> de repouso, continuará a sorrir a vida
              inteira.
            </li>
          </ul>
        </LeftTextBox>
        <img
          src={implant1}
          alt="Implante Dentário"
          title="técnica all-on-4"
          className="implant-img-first"
        />
      </div>
      <div className="grid-container">
        <img
          src={implant2}
          alt="Implante Dentário"
          title="técnica all-on-4"
          className="implant-img-second"
        />
        <RightTextBox>
          <p className={font.rR}>
            <b>Quem pode beneficiar com a All-on-4?</b>
            <br /> <br /> A All-on-4 é uma técnica de Implantologia Avançada que
            permite a colocação de dentição fixa com implantes, mesmo em casos
            de falta de osso severa.
            <br /> Esta técnica, ao contrário dos métodos tradicionais,
            aproveita as zonas onde ainda existe osso suficiente, em quantidade
            e qualidade, para a colocação dos implantes.
            <br /> Uma vez que dispensa a necessidade de enxerto ósseo, o
            All-on-4 torna-se uma solução mais rápida, menos invasiva e mais
            económica para a reabilitação oral.
          </p>
        </RightTextBox>
      </div>

    </Container>
  )
}

export default BoxWithText
