import React, { useRef } from "react"
import styled from "styled-components"
import OpenText from "../../images/openday/open-text-v2.svg"
import OpenDate from "../../images/openday/open-date.svg"
import { Link } from "gatsby"
import font from "../../fonts/fonts.module.scss"
import videoSrc from "../../videos/openday_ortodontia.mp4"
import poster from "../../images/openday/poster.png"


// Container principal que vai organizar a imagem e a área de texto abaixo
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;

   video {
    width: 100%;
  }
`

// Estilo para a imagem centralizada
const CenterImage = styled.img`
  max-width: 100%;
  height: auto;
  margin: 20px 0;
  width: 80%;
`

const BottomBackground = styled.div`
  width: 100%;
  background-color: #f8f6f5;
  padding: 97px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .img-central {
    width: 94%;
    margin: 40px 0;
  }

  .btn-open {
    width: 249px;
    height: 56px;
    /* UI Properties */
    background: #b7a99b 0% 0% no-repeat padding-box;
    opacity: 1;
    text-transform: uppercase;
    color: #fff;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

// Texto centralizado
const CenteredText = styled.p`
  font-size: 24px;
  color: #b7a99b;
  line-height: 1.5;
  margin: 0 6vw;

  b {
    font-weight: bold;
  }
`

const ImageWithTextMobile = () => {

  const video = useRef(null)
  const playBtn = useRef(null)

  function play() {
    if (video.current.paused) {
      video.current.play()
      playBtn.current.style.opacity = "0"
    } else {
      video.current.pause()
      playBtn.current.style.opacity = "1"
    }
  }
  function ended() {
    playBtn.current.style.opacity = "1"
  }

  return (
    <Container>
      {/* Imagem central */}
      <CenterImage src={OpenText} alt="Imagem Central" />

      {/* Fundo com o texto centralizado */}
      <BottomBackground>
        <CenteredText className={font.rR}>
          Faça já a inscrição no <b className={font.rB}>Open Day</b> de
          Ortodontia e transforme o seu sorriso. As vagas são limitadas!
        </CenteredText>
        <img src={OpenDate} alt="Imagem Central" className="img-central" />
        <Link className={`${font.rB} btn-open`} to="#form-openday">
          Inscreva-se já
        </Link>
      </BottomBackground>
      <video
        playsInline
        controls
        crossOrigin="anonymous"
        ref={video}
        poster={poster}
        onEnded={ended}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>

    </Container>
  )
}

export default ImageWithTextMobile
